<template>
  <div>
    <b-card>
      <b-form inline>
        <b-form-group
          label="Time range"
          label-sr-only
        >
          <date-range-picker
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'last60Days', 'last90Days']"
            :max-selectable-days="90"
            :timezone="asup.timezone"
          />
        </b-form-group>
        <b-form-group
          label="Log type"
          label-sr-only
        >
          <b-form-select
            v-model="selectedLogType"
            :options="logTypeOptions"
            size="sm"
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            v-model="inverse"
            switch
          >
            Inverse
          </b-form-checkbox>
        </b-form-group>
      </b-form>
    </b-card>

    <b-overlay :show="isLoading">
      <b-card>
        <template #header>
          <b-card-title>Log</b-card-title>
          <b-button
            size="sm"
            :variant="isDownloading ? 'secondary' : 'primary'"
            :disabled="isDownloading"
            @click="download"
          >
            <span v-if="!isDownloading">Download</span>
            <span v-else>Please wait...</span>
          </b-button>
        </template>

        <b-alert
          variant="info"
          :show="hasMoreData"
        >
          <div class="alert-body">
            <span>The log contains more data than displayed. To view the full log, please download it.</span>
          </div>
        </b-alert>

        <span style="white-space: break-spaces">{{ log }}</span>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, VBPopover, BFormCheckbox, BForm, BFormGroup, BOverlay, BFormSelect, BButton, BAlert, BCardTitle,
} from 'bootstrap-vue'
import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import NetWorkerService from '@/service/networker.service'
import moment from '@/libs/moment'

export default {
  components: {
    BCard,
    BFormCheckbox,
    BForm,
    BFormGroup,
    BOverlay,
    DateRangePicker,
    BFormSelect,
    BButton,
    BAlert,
    BCardTitle,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      timeRange: {
        range: 'today',
      },
      isLoading: false,
      log: null,
      hasMoreData: false,
      inverse: false,
      logTypeOptions: [
        { value: 0, text: 'Daemon' },
        { value: 1, text: 'RAP' },
      ],
      selectedLogType: 0,
      isDownloading: false,
    }
  },
  computed: {
    ...mapGetters({
      isCurrentUserHost: 'auth/isHost',
    }),
  },
  beforeMount() {
    this.$watch('timeRange', this.loadData)
    this.$watch('inverse', this.loadData)
    this.$watch('selectedLogType', this.loadData)
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.isLoading = true
      NetWorkerService.getLogAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        inverse: this.inverse,
        type: this.selectedLogType,
      }, { disableTenantFilter: true })
        .then(result => {
          this.log = result.log
          this.hasMoreData = result.hasMoreData
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    download() {
      this.isDownloading = true
      NetWorkerService.downloadLogAsync(this.asup.id, {
        from: moment(this.timeRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.timeRange.endDate).format('YYYY-MM-DD'),
        inverse: this.inverse,
        type: this.selectedLogType,
      }, { disableTenantFilter: true })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          const url = window.URL.createObjectURL(new Blob([result.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'log.txt')
          document.body.appendChild(link)
          link.click()
          link.remove()// you need to remove that elelment which is created before.
        })
        .finally(() => {
          this.isDownloading = false
        })
    },
  },
}
</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
